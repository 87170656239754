<template>
    <div>
        <div class="hot__container">
            <div class="header-text " >
                Зарабатывай с&nbsp;Перекрёстком!
            </div>
        </div>
        <div class="hot__sections" style="margin-top: 50px">
            <div class="hot__container">
                <div class="flex  flex__align-items-center flex__justify-content-space-between hot__sections-cnt">
                    <div class="hot__sections-col">
                        <div class="hot__sections-ttl flex flex__align-items-center flex__justify-content-space-between flex__column">
                            <img src="@/assets/img/perek_HR_icon_1.png" alt="" class="hot__sections-ttl__img" />
                            <div class="content-text">Работа <br>в супермаркете</div>
                            <router-link
                                :to="{ name: 'vacancy-lend-detail', params: { type: 'market' }, query: query}"
                                class="hot__sections-row__btn w-200p"
                                >Откликнуться</router-link
                            >
                        </div>
                    </div>
                    <div class="hot__sections-col">
                        <div class="hot__sections-ttl flex flex__align-items-center flex__justify-content-space-between flex__column">
                            <img src="@/assets/img/perek_HR_icon_2.png" alt="" class="hot__sections-ttl__img" />
                            <div class="content-text">Работа <br>в распределительном центре</div>
                            <router-link
                                :to="{ name: 'vacancy-lend-detail', params: { type: 'stock' }, query: query}"
                                class="hot__sections-row__btn w-200p"
                                >Откликнуться</router-link
                            >
                        </div>
                    </div>
                    <div class="hot__sections-col">
                        <div class="hot__sections-ttl flex flex__align-items-center flex__justify-content-space-between flex__column">
                            <img src="@/assets/img/perek_HR_icon_3.png" alt="" class="hot__sections-ttl__img" />
                            <div class="content-text">Работа <br>в доставке</div>
                            <router-link
                                :to="{ name: 'vacancy-lend-detail', params: { type: 'delivery' }, query: query}"
                                class="hot__sections-row__btn w-200p"
                                >Откликнуться</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="hot__container">
                              <a href="/vacancy/market?=undefined"><img src="@/assets/img/Banner_Perek_HR_Zarplata_Vishe.png" style="padding-block: 20px;"></a>
                            </div>

        </div>
           <div class="hot__vacancy">
            <div class="hot__container">
                <div class="hot__vacancy-cnt flex">
                    <div class="hot__vacancy-l flex flex__column gap-40 pb-20">
                        <div class="block-text">
                            3 простых шага – и включайся в команду!
                        </div>
                        <div class="flex flex__align-items-center " v-for="(v, i) in formReasons" :key="i">
                            <img :src="v.image" alt="" class="hot__reasons-col__image" />
                            <div class="content-text t-left pl-40" style="width: 280px;" v-html="v.title"></div>
                        </div>
                    </div>
                    <div class="hot__vacancy-r">
                        <form ref="vacancyForm" class="hot__vacancy-form" @submit.prevent="submit" onsubmit="ym(92372732,'reachGoal', 'perekrestok_submit_form', {pageurl: this.$route.query.page, nat: this.nationality, loc: this.city, vac: this.vacancy.title.replace(/\&nbsp\;/gi, ' ')}); return true;">
                            <div class="hot__vacancy-form__ttl">Заполни анкету</div>
                            <div class="hot__vacancy-form__txt">
                                и мы рассмотрим её <br />в ближайшее время
                            </div>

                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="fio">Полное имя</label>
                                <span class="form__error-txt __relative" v-show="errors.has('fio')">{{ errors.first('fio') }}</span>
                                <input
                                    id="fio"
                                    type="text"
                                    name="fio"
                                    class="form__inp __block"
                                    :class="{ __error: errors.has('fio') }"
                                    placeholder="Фамилия, имя, отчество"
                                    v-validate="'required|nameRu'"
                                    v-model="fio"
                                />
                            </div>

                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="phone">Телефон</label>
                                <span class="form__error-txt __relative" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
                                <input
                                    id="phone"
                                    type="text"
                                    name="phone"
                                    class="form__inp __block"
                                    :class="{ __error: errors.has('phone') }"
                                    placeholder="Введи телефон"
                                    v-validate="'required|tel'"
                                    v-mask="'+7(###) ###-##-##'"
                                    v-model="phone"
                                />
                            </div>

                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block">Гражданство</label>
                                <select
                                    id="nationality"
                                    name="nationality"
                                    class="form__inp __block"
                                    v-validate="'required'"
                                    v-model="nationality"
                                >
                                    <option value="Российская Федерация">Российская Федерация</option>
                                    <option value="Беларусь">Беларусь</option>
                                    <option value="Украина">Украина</option>
                                    <option value="Казахстан">Казахстан</option>
                                    <option value="Киргизия">Киргизия</option>
                                    <option value="Узбекистан">Узбекистан</option>
                                    <option value="Другое">Другое</option>
                                </select>
                            </div>

                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="city">Населенный пункт</label>
                                <span class="form__error-txt" v-show="errors.has('city')">{{ errors.first('city') }}</span>
                                <input
                                    id="city"
                                    type="text"
                                    name="city"
                                    class="form__inp __block"
                                    placeholder="Введи населенный пункт"
                                    :class="{ __error: errors.has('city') }"
                                    v-validate="'required'"
                                    v-model="city"
                                />
                            </div>
                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="careerObjective">Желаемая должность</label>
                                <select
                                    id="careerObjective"
                                    name="careerObjective"
                                    class="form__inp __block"
                                    v-validate="'required'"
                                    v-model="careerObjective"
                                >
                                    <option value="Автокурьер">Автокурьер</option>
                                    <option value="Бариста/кавист">Бариста/кавист</option>
                                    <option value="Велокурьер">Велокурьер</option>
                                    <option value="Водитель кат. С, СЕ">Водитель кат. С, СЕ</option>
                                    <option value="Водитель электроштабёлера">Водитель электроштабелёра</option>
                                    <option value="Директор супермаркета">Директор супермаркета</option>
                                    <option value="Заместитель директора супермаркета">Заместитель директора супермаркета</option>
                                    <option value="Кассир">Кассир</option>
                                    <option value="Курьер">Курьер</option>
                                    <option value="Менеджер кафе">Менеджер кафе</option>
                                    <option value="Мерчендайзер">Мерчендайзер</option>
                                    <option value="Оператор склада">Оператор склада</option>
                                    <option value="Пеший курьер">Пеший курьер</option>
                                    <option value="Повар/пекарь">Повар/пекарь</option>
                                    <option value="Приемщик товаров/товаровед">Приемщик товаров/товаровед</option>
                                    <option value="Продавец">Продавец</option>
                                    <option value="Руководитель пекарни/собственного производства">Руководитель пекарни/собственного производства</option>
                                    <option value="Сборщик заказов">Сборщик заказов</option>

                                </select>
                            </div>
                            <div class="hot__vacancy-form__group">
                                <label
                                class="form__checkbox flex __agreement"
                                :class="{ __active: agreement, __error: errors.has('agreement') }">
                                    <input
                                        type="checkbox"
                                        class="form__checkbox-inp"
                                        name="agreement"
                                        v-model="agreement"
                                        v-validate="'checkbox'"
                                    />
                                    <span class="form__checkbox-txt"
                                        >Я соглашаюсь и принимаю Условия Соглашения об оказании услуг по содействию в
                                        трудоустройстве и иных видах занятости, а также даю Согласие на обработку
                                        <a href="/pdf/soglasie-pd.docx" target="">персональных данных</a></span
                                    >
                                </label>
                            </div>

                            <div class="hot__vacancy-form__group __last">
                                <button type="submit" onclick="ym(92372732, 'reachGoal', 'perekrestok_click_form'), pageurl: this.$route.query.page, nat:this.nationality, loc: this.city, vac: this.vacancy.title.replace(/\&nbsp\;/gi, ' '); return true;" class="hot__btn" :disabled="disabled">Откликнуться</button>
                                <span class="form__lbl __block" v-if="disabled">Заявка отправляется...</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <modal :adaptive="true" name="modal"><div class="thanks-modal flex flex__align-items-center flex__justify-content-center"><span>Мы получили твою заявку</span></div></modal>

</template>

<script>
import { vacancies } from '../model'
import axios from 'axios'

export default {
  name: 'hot-vacancies',
  props: ['query'],
  data () {
    return {
      date: new Date(),
      widgetId: null,
      vacancies: [],
      reasons: [
        {
          title: 'Заполнение анкеты',
          image: require('@/assets/img/perek_HR_icons_9.png')
        },
        {
          title: 'Звонок менеджера',
          image: require('@/assets/img/perek_HR_icons_10.png')
        },
        {
          title: 'Интервью',
          image: require('@/assets/img/perek_HR_icons_11.png')
        }
      ],
      formReasons: [
        {
          title: 'Заполни анкету',
          image: require('@/assets/img/perek_HR_icons_12.png')
        },
        {
          title: 'Прими звонок от нашего менеджера и выбери подходящую вакансию',
          image: require('@/assets/img/perek_HR_icons_13.png')
        },
        {
          title: 'Приходи на&nbsp;интервью',
          image: require('@/assets/img/perek_HR_icons_14.png')
        }
      ],
      fio: '',
      phone: '',
      nationality: 'Российская Федерация',
      findLocations: [],
      stop_suggest: false,
      city: '',
      careerObjective: '',
      agreement: false,
      vacancy: null,
      params: null,
      disabled: false
    }
  },
  created () {
    this.vacancies = Object.keys(vacancies).map(id => ({ ...vacancies[id], id }))
    this.vacancy = vacancies[this.$route.params.type]
    this.params = this.query
  },
  mounted () {
    this.renderCaptcha()
  },
  destroyed () {
    window.smartCaptcha.destroy(this.widgetId)
  },
  computed: {
    error () { return 0 },
    img () {
      return require(`@/assets/img/${this.vacancy.img}.png`)
    },
    vacancyType () {
      if (this.vacancy.type === 'market') return 'Cупермаркет'
      if (this.vacancy.type === 'stock') return 'Распределительный центр'
      if (this.vacancy.type === 'delivery') return 'Доставка'
      return 'main'
    }
  },
  methods: {
    getTitle (title) {
      return `${title}${this.vacancy.word}`
    },
    renderCaptcha () {
      this.widgetId = window.smartCaptcha.render('captcha-container', {
        sitekey: 'ysc1_MiX3dOuZSzCQKeWkwHpWyjkupp8yChchXh0dNWk98de7a005',
        invisible: true, // Сделать капчу невидимой
        position: 'bottom-left'
      })
    },
    slideToForm () {
      const el = this.$refs.vacancyForm
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    submit () {
      if (!this.disabled) {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            return
          }
          this.disabled = true
          window.smartCaptcha.reset(this.widgetId)
          window.smartCaptcha.execute(this.widgetId)
          var _tmr = window._tmr || (window._tmr = [])
          _tmr.push({ type: 'reachGoal', id: 3421596, goal: 'Lead' })
          window.smartCaptcha.subscribe(this.widgetId, 'success',
            () => {
              const params = new FormData()
              params.append('fio', this.fio)
              params.append('phone', this.phone)
              params.append('nationality', this.nationality)
              params.append('city_name', this.city)
              params.append('career_objective', this.careerObjective)
              params.append('region_name', this.city)
              params.append('vacancy_name', 'main')
              params.append('want_type', 'main')
              params.append('want_position', 'main')
              params.append('type', 'main')
              params.append('utm_source', this.params.utm_source || '')
              params.append('utm_medium', this.params.utm_medium || '')
              params.append('utm_campaign', this.params.utm_campaign || '')
              this.disabled = true
              axios.post('https://rabota.perekrestok.ru/mail/sendTSX', params).then(() => {
                this.disabled = false
                this.fio = ''
                this.phone = ''
                this.city = ''
                this.careerObjective = ''
                this.$validator.reset()
                this.$modal.show('modal')
              })
              window.smartCaptcha.destroy(this.widgetId)
              this.renderCaptcha()
            })
        })
      }
    }
  }
}
</script>
