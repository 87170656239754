<template>
    <div>
        <div class="hot__vacancy">
            <div class="hot__container">
                <div class="hot__vacancy-cnt flex">
                    <div class="hot__vacancy-l flex flex__column gap-40 pb-20">
                        <div class="block-text">
                            3 простых шага – и включайся в команду!
                        </div>
                        <div class="flex flex__align-items-center " v-for="(v, i) in formReasons" :key="i">
                            <img :src="v.image" alt="" class="hot__reasons-col__image" />
                            <div class="content-text t-left pl-40" style="width: 280px;" v-html="v.title"></div>
                        </div>
                    </div>
                    <div class="hot__vacancy-r">
                        <form ref="vacancyForm" class="hot__vacancy-form" @submit.prevent="submit" onsubmit="ym(92372732,'reachGoal', 'perekrestok_submit_form', {pageurl: this.$route.query.page, nat: this.nationality, loc: this.city, vac: this.vacancy.title.replace(/\&nbsp\;/gi, ' ')}); return true;">
                            <div class="hot__vacancy-form__ttl">Заполни анкету</div>
                            <div class="hot__vacancy-form__txt">
                                и мы рассмотрим её <br />в ближайшее время
                            </div>

                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="fio">Полное имя</label>
                                <span class="form__error-txt __relative" v-show="errors.has('fio')">{{ errors.first('fio') }}</span>
                                <input
                                    id="fio"
                                    type="text"
                                    name="fio"
                                    class="form__inp __block"
                                    :class="{ __error: errors.has('fio') }"
                                    placeholder="Фамилия, имя, отчество"
                                    v-validate="'required|nameRu'"
                                    v-model="fio"
                                />
                            </div>

                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="phone">Телефон</label>
                                <span class="form__error-txt __relative" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
                                <input
                                    id="phone"
                                    type="text"
                                    name="phone"
                                    class="form__inp __block"
                                    :class="{ __error: errors.has('phone') }"
                                    placeholder="Введи телефон"
                                    v-validate="'required|tel'"
                                    v-mask="'+7(###) ###-##-##'"
                                    v-model="phone"
                                />
                            </div>


                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block">Гражданство</label>
                                <select
                                    id="nationality"
                                    name="nationality"
                                    class="form__inp __block"
                                    v-validate="'required'"
                                    v-model="nationality"
                                >
                                    <option value="Российская Федерация">Российская Федерация</option>
                                    <option value="Беларусь">Беларусь</option>
                                    <option value="Украина">Украина</option>
                                    <option value="Казахстан">Казахстан</option>
                                    <option value="Киргизия">Киргизия</option>
                                    <option value="Узбекистан">Узбекистан</option>
                                    <option value="Другое">Другое</option>
                                </select>
                            </div>

                            <div class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="city">Населенный пункт</label>
                                <span class="form__error-txt" v-show="errors.has('city')">{{ errors.first('city') }}</span>
                                <input
                                    id="city"
                                    type="text"
                                    name="city"
                                    class="form__inp __block"
                                    placeholder="Введи населенный пункт"
                                    :class="{ __error: errors.has('city') }"
                                    v-validate="'required'"
                                    v-model="city"
                                />
                            </div>
                            <div v-if="this.vacancy.type === 'market'" class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="careerObjective">Желаемая должность</label>
                                <select
                                    id="careerObjective"
                                    name="careerObjective"
                                    class="form__inp __block"
                                    v-validate="'required'"
                                    v-model="careerObjective"
                                >
                                    <option value="Бариста/кавист">Бариста/кавист</option>
                                    <option value="Директор супермаркета">Директор супермаркета</option>
                                    <option value="Заместитель директора супермаркета">Заместитель директора супермаркета</option>
                                    <option value="Кассир">Кассир</option>
                                    <option value="Курьер">Курьер</option>
                                    <option value="Менеджер кафе">Менеджер кафе</option>
                                    <option value="Мерчендайзер">Мерчендайзер</option>
                                    <option value="Повар/пекарь">Повар/пекарь</option>
                                    <option value="Продавец">Продавец</option>
                                    <option value="Приемщик товаров/товаровед">Приемщик товаров/товаровед</option>
                                    <option value="Руководитель пекарни/собственного производства">Руководитель пекарни/собственного производства</option>
                                    <option value="Сборщик заказов">Сборщик заказов</option>
                                </select>
                            </div>

                            <div v-if="this.vacancy.type === 'stock'" class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="careerObjective">Желаемая должность</label>
                                <select
                                    id="careerObjective"
                                    name="careerObjective"
                                    class="form__inp __block"
                                    v-validate="'required'"
                                    v-model="careerObjective"
                                >
                                    <option value="Водитель электроштабелера">Водитель электроштабелера</option>
                                    <option value="Оператор склада">Оператор склада</option>
                                </select>
                            </div>

                              <div v-if="this.vacancy.type === 'delivery'" class="hot__vacancy-form__group">
                                <label class="form__lbl __block" for="careerObjective">Желаемая должность</label>
                                <select
                                    id="careerObjective"
                                    name="careerObjective"
                                    class="form__inp __block"
                                    v-validate="'required'"
                                    v-model="careerObjective"
                                >
                                    <option value="Автокурьер">Автокурьер</option>
                                    <option value="Велокурьер">Велокурьер</option>
                                    <option value="Водитель кат. С, СЕ">Водитель кат. С, СЕ</option>
                                    <option value="Пеший курьер">Пеший курьер</option>
                                </select>
                            </div>

                            <div class="hot__vacancy-form__group">
                                <label
                                class="form__checkbox flex __agreement"
                                :class="{ __active: agreement, __error: errors.has('agreement') }">
                                    <input
                                        type="checkbox"
                                        class="form__checkbox-inp"
                                        name="agreement"
                                        v-model="agreement"
                                        v-validate="'checkbox'"
                                    />
                                    <span class="form__checkbox-txt"
                                        >Я соглашаюсь и принимаю Условия Соглашения об оказании услуг по содействию в
                                        трудоустройстве и иных видах занятости, а также даю Согласие на обработку
                                        <a href="/pdf/soglasie-pd.docx" target="">персональных данных</a></span
                                    >
                                </label>
                            </div>

                            <div class="hot__vacancy-form__group __last">
                                <button type="submit" onsubmit="ym(92372732,'reachGoal', 'perekrestok_submit_form', {pageurl: this.$route.query.page, nat: this.nationality, loc: this.city,
vac: this.vacancy.title.replace(/\&nbsp\;/gi, ' ')}); return true;" class="hot__btn" :disabled="disabled">Откликнуться</button>
                                <span class="form__lbl __block" v-if="disabled">Заявка отправляется...</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <modal :adaptive="true" name="modal"><div class="thanks-modal flex flex__align-items-center flex__justify-content-center"><span>Мы получили твою заявку</span></div></modal>
         <div class="hot__container mb-50">
            <div class="desc-block"  v-html="vacancy.text"/>
        </div>
        <div v-if="this.vacancy.type === 'market'"  class="hot__container">
            <a href="/vacancy/market?=undefined"><img src="@/assets/img/prk_banner_merch.png" style="padding-block: 20px;"></a>
        </div>
         <div class="hot__vacancy-head">
            <div class="hot__container">
                <div class="hot__vacancy-head-cnt flex flex__align-items-center">
                    <div class="hot__vacancy-head-l">
                        <div class="header-text" v-html="vacancy.title"/>

                        <div class="hot__vacancy-head-r __xs">
                            <img :src="img" alt="" class="hot__vacancy-head-img" />
                        </div>

                        <div class="hot__vacancy-head-meta flex flex__align-items-center">
                            <div class="hot__vacancy-head-meta-l">
                                <div class="hot__vacancy-head-schedule__b" v-html="vacancy.schedule"></div>
                            </div>
                            <div class="hot__vacancy-head-meta-r" @click="slideToForm()">
                                <span class="hot__btn">Откликнуться</span>
                            </div>
                        </div>
                    </div>
                    <div class="hot__vacancy-head-r __md">
                        <img :src="img" alt="" class="hot__vacancy-head-img" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import { vacancies } from '../model'
import axios from 'axios'

export default {
  name: 'hot-vacancy',
  components: {
  },
  props: ['query'],
  data () {
    return {
      date: new Date(),
      widgetId: null,
      reasons: [
        {
          title: 'Заполнение анкеты',
          image: require('@/assets/img/perek_HR_icons_9.png')
        },
        {
          title: 'Звонок менеджера',
          image: require('@/assets/img/perek_HR_icons_10.png')
        },
        {
          title: 'Интервью',
          image: require('@/assets/img/perek_HR_icons_11.png')
        }
      ],
      formReasons: [
        {
          title: 'Заполни анкету',
          image: require('@/assets/img/perek_HR_icons_12.png')
        },
        {
          title: '',
          image: require('@/assets/img/perek_HR_icons_13.png')
        },
        {
          title: 'Приходи на&nbsp;интервью',
          image: require('@/assets/img/perek_HR_icons_14.png')
        }
      ],
      fio: '',
      phone: '',
      nationality: 'Российская Федерация',
      findLocations: [],
      stop_suggest: false,
      city: '',
      careerObjective: '',
      agreement: false,
      vacancy: null,
      params: null,
      disabled: false
    }
  },
  created () {
    this.vacancy = vacancies[this.$route.params.type]
    this.formReasons[1].title = `Прими звонок от нашего менеджера и выбери подходящую вакансию и&nbsp;${this.vacancy.word}`
    this.params = this.query
  },
  mounted () {
    this.renderCaptcha()
  },
  destroyed () {
    window.smartCaptcha.destroy(this.widgetId)
  },
  computed: {
    error () { return 0 },
    img () {
      return require(`@/assets/img/${this.vacancy.img}.png`)
    },
    vacancyType () {
      if (this.vacancy.type === 'market') return 'Cупермаркет'
      if (this.vacancy.type === 'stock') return 'Распределительный центр'
      if (this.vacancy.type === 'delivery') return 'Доставка'
      return ''
    }
  },
  methods: {
    getTitle (title) {
      return `${title}${this.vacancy.word}`
    },
    renderCaptcha () {
      this.widgetId = window.smartCaptcha.render('captcha-container', {
        sitekey: 'ysc1_MiX3dOuZSzCQKeWkwHpWyjkupp8yChchXh0dNWk98de7a005',
        invisible: true, // Сделать капчу невидимой
        position: 'bottom-left'
      })
    },
    slideToForm () {
      const el = this.$refs.vacancyForm
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    submit () {
      if (!this.disabled) {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            return
          }
          this.disabled = true
          window.smartCaptcha.reset(this.widgetId)
          window.smartCaptcha.execute(this.widgetId)
          var _tmr = window._tmr || (window._tmr = [])
          _tmr.push({ type: 'reachGoal', id: 3421596, goal: 'Lead' })
          window.smartCaptcha.subscribe(this.widgetId, 'success',
            () => {
              const params = new FormData()
              params.append('fio', this.fio)
              params.append('phone', this.phone)
              params.append('nationality', this.nationality)
              params.append('city_name', this.city)
              params.append('career_objective', this.careerObjective)
              params.append('region_name', this.city)
              params.append('vacancy_name', this.vacancy.title.replace(/\&nbsp\;/gi, ' '))
              params.append('want_type', this.vacancyType)
              params.append('want_position', this.vacancy.title.replace(/\&nbsp\;/gi, ' '))
              params.append('type', this.vacancy.type)
              params.append('utm_source', this.params.utm_source || '')
              params.append('utm_medium', this.params.utm_medium || '')
              params.append('utm_campaign', this.params.utm_campaign || '')
              this.disabled = true
              axios.post('https://rabota.perekrestok.ru/mail/sendTSX', params).then(() => {
                this.disabled = false
                this.fio = ''
                this.phone = ''
                this.city = ''
                this.careerObjective = ''
                this.$validator.reset()
                this.$modal.show('modal')
              })
              window.smartCaptcha.destroy(this.widgetId)
              this.renderCaptcha()
            })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.thanks-modal {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 50px;
    text-align: center;
    height: 100%;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]),
textarea,
select,
.custom-select {
    font-size: 16px;
    font-family: var(--pka);
    outline: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.form {
    &__lbl,
    &__inp {
        &.__block {
            display: block;
        }
    }

    &__lbl {
        font-weight: 600;
        margin-bottom: 10px;
    }

    &__select .custom-select__label,
    &__datepicker input,
    &__inp,
    &__textarea {
        width: 100%;
        border-radius: 10px;
        padding: 20px 15px;
        background-color: var(--cl-global-invert);
        border: 1px solid var(--cl-lnk-bg-grey);

        &.__error {
            border-color: var(--cl-red) !important;
        }
    }

    &__textarea {
        max-width: 100%;
    }

    &__datepicker {
        &.__error {
            input {
                border-color: var(--cl-red) !important;
            }
        }
    }

    &__select {
        .custom-select {
            &__dropdown {
                &--is-open {
                    margin-top: 10px;
                }
            }
        }

        &.__error {
            .custom-select__label {
                border-color: var(--cl-red) !important;
            }
        }
    }

    &__checkbox,
    &__radio {
        position: relative;
        cursor: pointer;

        &.__active {
            &:after {
                display: block;
            }
        }

        &:before,
        &:after {
            content: '';
            display: block;
            border-radius: 100%;
        }

        &:before {
            width: 26px;
            height: 26px;
            background-color: var(--cl-global-invert);
            border: 1px solid var(--cl-lnk-bg-grey);
            box-sizing: border-box;
        }

        &:after {
            display: none;
            position: absolute;
            left: 8px;
            width: 10px;
            height: 10px;
            background-color: var(--cl-green);
        }

        &-inp {
            display: none;
        }

        &-txt {
            padding-left: 8px;
            width: calc(100% - 26px);
        }
    }

    &__checkbox {
        line-height: 1;

        &.__agreement {
            font-size: 14px;
            color: var(--cl-grey);
        }

        &:before {
            border-radius: 8px;
        }

        &:after {
            top: 9px;
            width: 12px;
            height: 10px;
            background: url('@/assets/img/svg/checkbox-red.svg') no-repeat 0 0;
        }

        &.__error {
            &:before {
                border-color: var(--cl-red) !important;
            }
        }
    }

    &__error-txt {
        position: absolute;
        top: 5px;
        right: 0;
        color: var(--cl-red);
        font-size: 12px;

        &.__relative {
            display: block;
            position: relative;
            top: auto;
            right: auto;
            margin-top: -10px;
            margin-bottom: 10px;
        }
    }
}
.vdp-datepicker {
    &__icon {
        position: absolute;
        left: calc(100% - 32px);
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    .vdp-datepicker__calendar {
        width: 352px;
        top: 100%;
        padding: calc(var(--gs) * 3);
        border: none;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);

        @media (--mobile) {
            width: 272px;
            padding: calc(var(--gs) * 1);
        }

        header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: calc(var(--gs) * 2);

            span {
                float: none;
                width: auto;
                max-width: calc(99.9% - 80px);
            }

            .prev,
            .next {
                width: 40px;
                flex-shrink: 0;
                background-color: var(--cl-green);
                background-image: url('@/assets/img/svg/arrow_calendar.svg');
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 10px auto;
                border-radius: 50%;
                font-size: 0;
                transition: all var(--ease) ease;

                &:not(.disabled):hover {
                    background-color: var(--cl-dk-red);
                    background-image: url('@/assets/img/svg/arrow_calendar.svg');
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: 10px auto;
                }

                &.disabled {
                    background-color: var(--cl-grey);
                }

                &::after {
                    content: none;
                }
            }

            .prev {
                transform: rotate(-180deg);
            }

            .up {
                padding-right: calc(var(--gs) * 3);
                transition: all var(--time) ease;
                background-image: url('@/assets/img/svg/arrow_down.svg');
                background-repeat: no-repeat;
                background-position: calc(100% - 5px) 50%;

                &.disabled {
                    background-image: url('@/assets/img/svg/arrow_down.svg');
                }
            }

            .up:not(.disabled):hover {
                background-color: transparent;
                background-image: url('@/assets/img/svg/arrow_down.svg');
                background-repeat: no-repeat;
                background-position: calc(100% - 5px) 50%;
                color: var(--cl-green);
            }
        }

        .cell {
            &.day-header {
                font-size: 14px;
                color: var(--cl-grey);
                text-transform: lowercase;
                width: 38px;
                height: 38px;
                line-height: 38px;
                margin-bottom: 5px;
                margin-right: 5px;
                padding: 0;

                @media (--mobile) {
                    width: calc((99.9% - 30px) / 7);
                }

                &:nth-child(7n) {
                    margin-right: 0;
                }
            }

            &.day {
                width: 38px;
                height: 38px;
                padding: 0;
                margin-bottom: 5px;
                margin-right: 5px;
                font-size: 16px;
                line-height: 38px;
                border: 1px solid var(--cl-elem-grey);
                font-family: var(--pka);
                transition: all var(--time) ease;

                &:nth-child(7n) {
                    margin-right: 0;
                }

                @media (--mobile) {
                    width: calc((99.9% - 30px) / 7);
                    font-size: 14px;
                }

                &:not(.blank):not(.disabled):hover {
                    border-color: var(--cl-green);
                    background-color: var(--cl-green);
                    color: var(--cl-global-invert);
                }

                &.weekend {
                    color: var(--cl-green);
                }
            }

            &.month,
            &.year {
                font-family: var(--pka);
                transition: all var(--time) ease;

                &:not(.blank):not(.disabled):hover {
                    border: 1px solid var(--cl-green);
                }
            }

            &.selected {
                background: var(--cl-green);
                color: var(--cl-global-invert);

                &:hover {
                    background: var(--cl-green);
                }
            }
        }

        .disabled {
            color: var(--cl-grey);
        }
    }
}

</style>
